@use '../variables';
@use 'responsive';

// Typography styles

@mixin heading-1 {
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  font-weight: var(--h1-font-weight);
  letter-spacing: var(--h1-letter-spacing);
  font-style: var(--h1-font-style);
  text-transform: var(--h1-text-transform);
}

@mixin heading-2 {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  font-weight: var(--h2-font-weight);
  letter-spacing: var(--h2-letter-spacing);
  font-style: var(--h2-font-style);
  text-transform: var(--h2-text-transform);
}

@mixin heading-3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-weight: var(--h3-font-weight);
  letter-spacing: var(--h3-letter-spacing);
  font-style: var(--h3-font-style);
  text-transform: var(--h3-text-transform);
}

@mixin heading-4 {
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
  font-weight: var(--h4-font-weight);
  letter-spacing: var(--h4-letter-spacing);
  font-style: var(--h4-font-style);
  text-transform: var(--h4-text-transform);
}

@mixin heading-5 {
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  font-weight: var(--h5-font-weight);
  letter-spacing: var(--h5-letter-spacing);
  font-style: var(--h5-font-style);
  text-transform: var(--h5-text-transform);
}

@mixin heading-6 {
  font-size: var(--h6-font-size);
  line-height: var(--h6-line-height);
  font-weight: var(--h6-font-weight);
  letter-spacing: var(--h6-letter-spacing);
  font-style: var(--h6-font-style);
  text-transform: var(--h6-text-transform);
}

@mixin heading-7 {
  font-size: var(--h7-font-size);
  line-height: var(--h7-line-height);
  font-weight: var(--h7-font-weight);
  letter-spacing: var(--h7-letter-spacing);
  font-style: var(--h7-font-style);
  text-transform: var(--h7-text-transform);
}

@mixin body-1 {
  font-size: var(--body1-font-size);
  line-height: var(--body1-line-height);
  font-weight: var(--body1-font-weight);
  letter-spacing: var(--body1-letter-spacing);
  font-style: var(--body1-font-style);
  text-transform: var(--body1-text-transform);
}

@mixin body-2 {
  font-size: var(--body2-font-size);
  line-height: var(--body2-line-height);
  font-weight: var(--body2-font-weight);
  letter-spacing: var(--body2-letter-spacing);
  font-style: var(--body2-font-style);
  text-transform: var(--body2-text-transform);
}

@mixin subtitle-1 {
  font-size: var(--sub1-font-size);
  line-height: var(--sub1-line-height);
  font-weight: var(--sub1-font-weight);
  letter-spacing: var(--sub1-letter-spacing);
  font-style: var(--sub1-font-style);
  text-transform: var(--sub1-text-transform);
}

@mixin subtitle-2 {
  font-size: var(--sub2-font-size);
  line-height: var(--sub2-line-height);
  font-weight: var(--sub2-font-weight);
  letter-spacing: var(--sub2-letter-spacing);
  font-style: var(--sub2-font-style);
  text-transform: var(--sub2-text-transform);
}

@mixin button-1 {
  font-size: var(--button1-font-size);
  line-height: var(--button1-line-height);
  font-weight: var(--button1-font-weight);
  letter-spacing: var(--button1-letter-spacing);
  font-style: var(--button1-font-style);
  text-transform: var(--button1-text-transform);
}

@mixin button-2 {
  font-size: var(--button2-font-size);
  line-height: var(--button2-line-height);
  font-weight: var(--button2-font-weight);
  letter-spacing: var(--button2-letter-spacing);
  font-style: var(--button2-font-style);
  text-transform: var(--button2-text-transform);
}

@mixin caption {
  font-size: var(--caption-font-size);
  line-height: var(--caption-line-height);
  font-weight: var(--caption-font-weight);
  letter-spacing: var(--caption-letter-spacing);
  font-style: var(--caption-font-style);
  text-transform: var(--caption-text-transform);
}

@mixin overline {
  font-size: var(--overline-font-size);
  line-height: var(--overline-line-height);
  font-weight: var(--overline-font-weight);
  letter-spacing: var(--overline-letter-spacing);
  font-style: var(--overline-font-style);
  text-transform: var(--overline-text-transform);
}

@mixin nav-1 {
  font-size: var(--nav1-font-size);
  line-height: var(--nav1-line-height);
  font-weight: var(--nav1-font-weight);
  letter-spacing: var(--nav1-letter-spacing);
  font-style: var(--nav1-font-style);
  text-transform: var(--nav1-text-transform);
}

@mixin nav-2 {
  font-size: var(--nav2-font-size);
  line-height: var(--nav2-line-height);
  font-weight: var(--nav2-font-weight);
  letter-spacing: var(--nav2-letter-spacing);
  font-style: var(--nav2-font-style);
  text-transform: var(--nav2-text-transform);
}
