@use 'src/assets/styles/app';

/** ADYEN CSS **/

/* stylelint-disable-next-line */
.adyen-checkout__payment-method {
  /* Payment method container */
  @include app.body-1;
}

/* stylelint-disable-next-line */
.adyen-checkout__payment-method--selected {
  /* Payment method that has been selected */
}

/* stylelint-disable-next-line */
.adyen-checkout__payment-method__header {
  /* Payment method icon and name */
}

/* stylelint-disable-next-line */
.adyen-checkout__payment-method__radio {
  /* Radio button in payment method header */
}

/* stylelint-disable-next-line */
.adyen-checkout__payment-method__radio--selected {
  /* Selected radio button in payment method header */
}

/* stylelint-disable-next-line */
.adyen-checkout__payment-method__name {
  /* Payment method name in the payment method header */
}

/* stylelint-disable-next-line */
.adyen-checkout__spinner__wrapper {
  /* Spinning icon */
}

/* stylelint-disable-next-line */
.adyen-checkout__button {
  /* Buttons */
}

/* stylelint-disable-next-line */
.adyen-checkout__button--pay {
  @include app.button-1;

  background: app.$black;
  color: app.$yellow;
  margin-top: 30px !important;

  span {
    @include app.ellipsis;
  }

  img {
    display: none;
  }

  &:hover {
    background: app.$black;
    box-shadow: none;
  }
}

/* stylelint-disable-next-line */
.adyen-checkout__field {
  /* Form field container */
}

/* stylelint-disable-next-line */
.adyen-checkout__label {
  /* Form label container */
}

/* stylelint-disable-next-line */
.adyen-checkout__label__text {
  /* Text element inside the form label container */
  @include app.body-2;
}

/* stylelint-disable-next-line */
.adyen-checkout__input {
  /* Input fields */
  @include app.input;

  width: 100%;
}

/* stylelint-disable-next-line */
.adyen-checkout__input--error {
  /* Error state for the input fields */
}

/* stylelint-disable-next-line */
.adyen-checkout__error-text {
  /* Error message text */
}

/* stylelint-disable-next-line */
.adyen-checkout__card__cardNumber__input {
  /* Input field for the card number */
  @include app.input;

  width: 100%;
}

/* stylelint-disable-next-line */
.adyen-checkout__field--expiryDate {
  /* Input field for the expiry date */
  @include app.input;

  width: 100%;
}

/* stylelint-disable-next-line */
.adyen-checkout__field__cvc {
  /* Input field for the CVC security code */
  @include app.input;
}

/* stylelint-disable-next-line */
.adyen-checkout__card__holderName {
  /* Input field for cardholder name */
  @include app.input;

  width: 100%;
  height: auto;
}

/* stylelint-disable-next-line */
.adyen-checkout__checkbox__input {
  /* Checkboxes */
}

/* stylelint-disable-next-line */
.adyen-checkout__checkbox__label {
  /* Checkbox labels */
}

/* stylelint-disable-next-line */
.adyen-checkout__radio_group__input {
  /* Radio buttons */
}

/* stylelint-disable-next-line */
.adyen-checkout__dropdown__button {
  /* Dropdown button showing list of options */
}

/* stylelint-disable-next-line */
.adyen-checkout__dropdown__list {
  /* Dropdown list */
}

/* stylelint-disable-next-line */
.adyen-checkout__dropdown__element {
  /* Elements in the dropdown list */
}

/* stylelint-disable-next-line */
.adyen-checkout__link {
  /* Links */
}

/* stylelint-disable-next-line */
ul.adyen-checkout__payment-methods-list {
  padding-left: 0;
}

/* stylelint-disable-next-line */
.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: app.$black;
}

/* stylelint-disable-next-line */
.adyen-checkout__input--focus, .adyen-checkout__input--focus:hover {
  border: 1px solid app.$grey-4;
  box-shadow: none;
}

/* stylelint-disable-next-line */
.adyen-checkout__loading-input__form {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.input-field {
  @include app.input;
}

/* stylelint-disable-next-line */
.adyen-checkout__input::placeholder, .input-field::placeholder {
  @include app.caption;
}
