@use 'src/assets/styles/app';

// Axeptio

/* stylelint-disable-next-line selector-id-pattern */
#axeptio_overlay {
  z-index: app.$axeptio-z;

  .ax-website-overlay {
    z-index: app.$axeptio-z;
  }
}

// CDK

.cdk-global-scrollblock {
  overflow: hidden;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: app.$overlay-z;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: app.$overlay-z;
}

.cdk-overlay-pane {
  position: static;
  pointer-events: auto;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: app.$overlay-z;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 32%);
}

.cdk-overlay-transparent-backdrop {
  transition:
    visibility 1ms linear,
    opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.panel {
  margin-left: auto;
}

.overlay {
  width: 100%;
  height: 100%;
}
