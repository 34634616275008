@use '../variables';
@use 'typography';
@use 'responsive';
@use 'utils';

@mixin input {
  display: block;
  width: 238px;
  min-width: 0;
  height: 58px;
  min-height: 58px;

  .wrapper {
    @include transition($quick-timing, $default-easing, border-color);

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    min-height: 58px;
    background-color: $white;
    border: solid 1px $grey;
    border-radius: 8px;
    padding: 0 18px;

    .inner {
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;
      gap: 2px;
      min-width: 0;

      .label {
        @include caption;
        @include transition($quick-timing, $default-easing, transform);
        @include ellipsis;

        pointer-events: none;
        transform: translateY(10px);
        transform-origin: center left;
      }

      .input {
        @include caption;
        @include transition($quick-timing, $default-easing, color);
        @include ellipsis;

        width: 100%;
        height: 19px;
        border: 0;
        outline: none;
        background: none;

        &:autofill + .label {
          transform: scale(0.8);
        }
      }
    }

    &.value {
      .inner {
        .label {
          transform: scale(0.8);
        }
      }
    }

    &.focus {
      border: solid 1px $grey-5;
    }

    &.disabled {
      opacity: 0.7;
      background: $grey-3;
      cursor: default;
    }
  }

  &.ng-invalid.ng-dirty {
    .wrapper {
      .inner {
        .input {
          color: $red;
        }
      }
    }
  }
}

@mixin text-input {
  @include input;

  app-icon-button {
    z-index: 1;
    cursor: pointer;
  }

  .wrapper {
    cursor: text;

    @include transition($quick-timing, $default-easing, padding-right);

    .warning {
      position: absolute;
      top: 15px;
      right: 18px;
      opacity: 0;
      transform: translateX(5px);
      color: $red;
      pointer-events: none;

      @include transition($quick-timing, $default-easing, opacity, transform);
    }
  }

  &.ng-invalid.ng-dirty {
    .wrapper {
      padding-right: 50px;

      .warning {
        opacity: 1;
        transform: none;
      }
    }
  }
}

@mixin options {
  @include elevation(2);

  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: solid 1px $grey-5;
  border-radius: 8px;
  width: 100%;
  list-style: none;
  padding: 0;
  overflow: auto hidden;
  animation: open-select $quick-timing $default-easing;

  &.top {
    top: inherit;
    bottom: calc(100% - 1px);
  }

  &[hidden] {
    display: none;
  }

  > * {
    @include caption;

    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    min-height: 42px;
    padding: 9px 18px;
    cursor: pointer;
    transition: background-color $quick-timing $default-easing;

    &:hover {
      background-color: $grey-3;
    }
  }
}

@mixin form {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;

  > * {
    width: 100%;
    flex: 1 auto;
    display: inline-flex;
    flex-direction: row;
    gap: 28px;

    .form-cols {
      flex: 1 auto;
      width: 100%;
    }
  }

  .form-checkboxes {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form-error {
    display: block;

    span {
      @include typography.body-2;

      color: $red;
      display: block;
    }

    &:empty {
      display: none;
    }
  }

  @include device('small') {
    gap: 20px;

    > * {
      flex-direction: column;
      gap: 20px;

      .form-cols {
        width: 100%;
      }
    }
  }
}
