@use 'src/assets/styles/app';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: app.$font-stack;
  color: app.$font-color;
  background-color: app.$bg-color;
}

pre,
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  border: none;
  border-bottom: solid 1px app.$grey-2;
}

// Default presets

h1 {
  @include app.heading-1;
}

h2 {
  @include app.heading-2;
}

h3 {
  @include app.heading-3;
}

h4 {
  @include app.heading-4;
}

h5 {
  @include app.heading-5;
}

h6 {
  @include app.heading-6;
}
