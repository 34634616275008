// Load specific fonts

/* Helvetica Neue LT Std (500) - Md */
@font-face {
  font-family: helvetica;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/helvetica-neue-lt-std-md.woff2') format('woff2'),
    url('../../fonts/helvetica-neue-lt-std-md.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Helvetica Neue LT Std (700) - Bd */
@font-face {
  font-family: helvetica;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/helvetica-neue-lt-std-bd.woff2') format('woff2'),
    url('../../fonts/helvetica-neue-lt-std-bd.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Helvetica Neue LT Std (750) - Hv */
@font-face {
  font-family: helvetica;
  font-style: normal;
  font-weight: 750;
  font-display: swap;
  src:
    local(''),
    url('../../fonts/helvetica-neue-lt-std-hv.woff2') format('woff2'),
    url('../../fonts/helvetica-neue-lt-std-hv.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
