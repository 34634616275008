@use 'sass:map';
@use '../variables';

// Responsive

@mixin device($breakpoint, $include: true) {
  @if $include == true {
    @media (max-width: map.get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @media (min-width: (map.get($breakpoints, $breakpoint) + 1px)) {
      @content;
    }
  }
}

@mixin layout-width($top-spacing: 0, $bottom-spacing: 0, $margin: false) {
  @if $margin != true {
    width: 100%;
    padding: $top-spacing $layout-padding $bottom-spacing;
  } @else {
    width: auto;
    margin: $top-spacing $layout-padding $bottom-spacing;
  }
}

@mixin push-to-edge($left: true, $right: true, $keep-padding: false) {
  @if $left == true {
    margin-left: calc(-1 * $layout-padding);

    @if $keep-padding == true {
      padding-left: $layout-padding;
    }
  }

  @if $right == true {
    margin-right: calc(-1 * $layout-padding);

    @if $keep-padding == true {
      padding-right: $layout-padding;
    }
  }
}
